import 'jquery';
import { default as $ } from 'jquery';

$(document).ready(function () {
    $('.wc-pao-addon-image-swatch:not(:first-child)').addClass("-cards");
    $('.wc-pao-addon-image-swatch:first-child').addClass("-first-card");

    $(".wc-pao-required-addon").on('change','input[type="radio"]', (function() { 
        if($('input[value="yes-add-a-personalised-message-gift-card"').is(':checked')) {
            $('.wc-pao-addon-container:nth-child(2)').show();
            $('.wc-pao-addon-container:nth-child(3)').show();
        } else {
            $('.wc-pao-addon-container:nth-child(2)').hide();
            $('.wc-pao-addon-container:nth-child(3)').hide();
        };
    }));

    // if(window.location.href.indexOf("collection-wonky-boxes") > -1) {
    //     if($("#addon-9313-0").val() === "") {
    //         $('#wc-stripe-payment-request-wrapper').addClass('disable-button');
    //         console.log('no option on load');
    //     };

    //     $(".wc-pao-required-addon").on('change','select', (function() { 
    //         if($(this).val() === "") {
    //             $('#wc-stripe-payment-request-wrapper').addClass('disable-button');
    //             console.log('no option');
    //         } else {
    //             $('#wc-stripe-payment-request-wrapper').removeClass('disable-button');
    //             console.log('option selected');
    //         };
    //     }));
    // };
});
