import 'jquery';

jQuery(document).ready(function() {
    jQuery(".navBlockList .item a").click(function(event) {
        event.preventDefault();
        
        var url = jQuery(this).attr('href');
        var navBlockAnchor = jQuery(this).attr('data-nav-anchor');

        if (jQuery(`*[data-block-anchor="${navBlockAnchor}"]`).length > 0) {
            jQuery('html, body').animate({
                scrollTop: jQuery(`[data-block-anchor="${navBlockAnchor}"]`).offset().top - jQuery("#header").height()
            }, 500);
        }
        else if(typeof(url) != 'undefined' && url.trim() != "") {
            window.location = url;
        }
    });
    
    jQuery(document).scroll( function() {
        if(window.scrollY >= window.innerHeight * 0.3 && jQuery('.backToTop').length > 0 && !jQuery('.backToTop').hasClass('-active'))
            jQuery('.backToTop').addClass('-active');
        else if(window.scrollY <= window.innerHeight * 0.3 && jQuery('.backToTop').length > 0 && jQuery('.backToTop').hasClass('-active'))
            jQuery('.backToTop').removeClass('-active');
    });
    
    jQuery('.backToTop').click((e) => {
        e.preventDefault();
        jQuery('html, body').animate({
            scrollTop: 0
        }, 500);
    });
});