import { default as $ } from 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

$(document).ready( function() {
    $('.m-timeline-events.-isCarousel').each( function() {
        
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: false,
            margin: 20,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: '<button aria-label="Next" class="m-slick-arrow -timeline -next slick-next"><i class="fas fa-chevron-right m-slick-arrow-icon"></i></button>',
            prevArrow: '<button aria-label="Previous" class="m-slick-arrow -timeline -prev slick-prev me-4"><i class="fas fa-chevron-left m-slick-arrow-icon"></i></button>',
            draggable: true,
            appendDots: jQuery(this).parent(),
            appendArrows: jQuery(this).parent(),
            customPaging: function(slider, i) {
                return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
            },
            pauseOnHover: false,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2,
                        arrows: true,

                    }
                },
                {
                    breakpoint: 768,

                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                    }
                }
            ]
        };


        $(this).slick(slickConf);
    });

    var slider = $('#timeline');

    function jumpBack() {
        setTimeout(function() {
            slider.slick("slickGoTo", 0);
          },3000);
      }

      slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if (currentSlide === 4 ) {
          jumpBack();
        }
      });
});