import { default as $ } from 'jquery';
import 'slick-carousel';
import {getArrowIcon, getSplatIcon, SLICK_CONF} from '../slick-common/slick-common';

$(document).ready( function() {
    $('.contentListings.-timeline.-isCarousel').each( function() {
        let itemsPerRow = jQuery(this).data('itemsPerRow') ? parseInt(jQuery(this).data('itemsPerRow')) : 3;

        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: true,
            margin: 20,
            slidesToShow: itemsPerRow,
            arrows: true,
            draggable: true,
            prevArrow: '<button type="button" class="slick-prev -splat -hoverSplat">' + getArrowIcon({ arrowType: 'prev' }) + getSplatIcon() + '</button>',
            nextArrow: '<button type="button" class="slick-next -splat -hoverSplat">' + getArrowIcon({ arrowType: 'next' }) + getSplatIcon() + '</button>',
            appendDots: jQuery(this).parent(),
            appendArrows: jQuery(this).parent(),
            customPaging: function(slider, i) {
                return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
            },
            pauseOnHover: false,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,
                        arrows: true,

                    }
                },
                {
                    breakpoint: 767,

                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                    }
                }
            ]
        };

        if(jQuery(this).hasClass('slick-initialized'))
            jQuery(this).slick('unslick');

        jQuery(this).not('.slick-initialized').slick(slickConf);
    });
});