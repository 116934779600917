// import 'jquery';
// import ReactDOMServer from 'react-dom/server';
// import { CardComponent } from 'parachute-elements';

// const fetchItems = (endpointUrl, filter = null, method = 'post') => {
//     return fetch(endpointUrl, {
//         method: method,
//         body: filter != null ? JSON.stringify(filter) : '',
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     })
//         .then(response => response.json())
//         .then(json => json);
// }

// const buildRenderHtml = (json, renderComponent = CardComponent) => {
//     let cards = [];
//     if(json && json.collection && Array.isArray(json.collection) && json.collection.length > 0) {
//         for(let i = 0; i < json.collection.length; i++) {
//             json.collection[i].htmlClasses = ['-flexBasis33'];
//             cards.push(renderComponent(json.collection[i]));
//         }
//     }
//     else{
//         console.error($`No results returned`)
//     }

//     return cards;
// }

// const render = (reactElements) => {
//     const reactContext = document.getElementById("react-listings-root");

//     if(reactContext != null && reactElements && Array.isArray(reactElements) && reactElements.length > 0) {
//         for(let i = 0; i < reactElements.length; i++) {
//             const renderStr = ReactDOMServer.renderToStaticMarkup(reactElements[i]);
//             reactContext.insertAdjacentHTML('beforeend', renderStr);
//         }
//     }
// }

// let _filter = null;
// const handleLoadMoreClick = (that, e, filter, rendererComponent = CardComponent, listingsContainerSelector = '#react-listings-root') => {
//     e.preventDefault();

//     const btnText = jQuery(that).find('.text');
//     const btnTextOriginal = btnText[0].innerHTML;
//     if(btnText.length > 0)
//         btnText[0].innerHTML = 'Loading...';

//     if(_filter != null && filter != _filter)
//         filter = _filter;

//     if(filter.page == null)
//         filter.page = 1;
//     else if(typeof(filter.page) == 'string' || isNaN(filter.page))
//         filter.page = parseInt(filter.page);

//     filter.page = filter.page + 1;
//     const url = jQuery(that).attr('data-load-more-url');
//     let method = jQuery(that).attr('data-load-more-method');
//     const loadMoreOnScroll = jQuery(that).attr('data-load-more-on-scroll') == 'True';

//     if(!method)
//         method = 'post';

//     if(filter && url) {
//         const json = fetchItems(url, {
//             Filter: filter
//         })
//             .then(json => {
//                 const cards = buildRenderHtml(json, rendererComponent);
//                 render(cards);
//                 _filter = json.filter;

//                 if(btnText.length > 0)
//                     btnText[0].innerHTML = btnTextOriginal;

//                 if(jQuery(listingsContainerSelector).length > 0 && jQuery(listingsContainerSelector).children().length == _filter.resultsCountTotal)
//                     jQuery(that).addClass('-srOnly');
//             });
//     }
// }

// export { fetchItems, buildRenderHtml, render, handleLoadMoreClick }