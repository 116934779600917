import 'jquery';
import { default as $ } from 'jquery';

const handleControlClick = e => {
    e.preventDefault();

    let el = jQuery(e.currentTarget);
    let componentId = jQuery(e.currentTarget).attr('data-attr-component-id');
    let id = jQuery(e.currentTarget).attr('data-attr-id');
    let contentEl = jQuery(`#accordionContent-${id}`);

    if(contentEl.length > 0) {
        jQuery(el).parent().toggleClass('-active');
        jQuery(el).toggleClass('-active');
        contentEl.slideToggle(125).toggleClass('-active');
    }
};

jQuery(document).ready( function() {
    //jQuery('.accordionsList:not(.-inner) > .accordion:first-child').addClass('-active');
    //jQuery('.accordionsList:not(.-inner) > .accordion:first-child > .button').addClass('-active');
    //jQuery('.accordionsList:not(.-inner) > .accordion:first-child > .accordionContent').addClass('-active').slideToggle(200);
    jQuery('.accordion > .button').click(handleControlClick);

    $(window).on("load",function() {
       $(".m-single-product-accordion-content").slideUp();
       $(".m-single-product-accordion-chevron").removeClass("-upsideDown");
    });
    
    $(".m-single-product-accordion-heading").on("click", function(){
       if ($(this).closest(".m-single-product-accordion-section").hasClass("-noShow")) {
          $(".m-single-product-accordion-content").slideUp();
          $(".m-single-product-accordion-chevron").removeClass("-upsideDown");
          $(".m-single-product-accordion-section").addClass("-noShow");
          $(this).closest(".m-single-product-accordion-section").removeClass("-noShow");
          $(this).closest(".m-single-product-accordion-section").find(".m-single-product-accordion-content").slideToggle();
          $(this).closest(".m-single-product-accordion-section").find(".m-single-product-accordion-chevron").toggleClass("-upsideDown");
       } else {
          $(this).closest(".m-single-product-accordion-section").addClass("-noShow");
          $(".m-single-product-accordion-content").slideUp();
          $(".m-single-product-accordion-chevron").removeClass("-upsideDown");
       };
    });
});