import 'jquery';

jQuery(document).ready(function () {
    if (jQuery(window).width() < 992) {
        jQuery('#header').addClass('-willBeSticky');
    } else {
        jQuery('#header').removeClass('-willBeSticky');
    };
});

jQuery(window).scroll(function() {
    if (jQuery(window).width() > 992) {
        if( jQuery(window).scrollTop() > 550 ) {
            jQuery(".m-header.-scroll").slideDown();
        } else {
            jQuery('.m-header.-scroll').slideUp();
        };
    };
});
