import 'jquery';
import { default as $ } from 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

$('#allList').slick({
    dots: false,
    infinite: true,
    margin: 20,
    slidesToShow: 4,
    arrows: true,
    draggable: true,
    nextArrow: '<button aria-label="Next" class="m-slick-arrow -next slick-next"><i class="fas fa-chevron-right m-slick-arrow-icon"></i></button>',
    prevArrow: '<button aria-label="Previous" class="m-slick-arrow -prev slick-prev me-4"><i class="fas fa-chevron-left m-slick-arrow-icon"></i></button>',
    appendArrows: jQuery('#allButton').parent(),
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 980,
            settings: {
                slidesToShow: 2,
                arrows: true,

            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 1,
                arrows: true,
            }
        }
    ]
    });


jQuery('#allButton').on('click', function() {
    jQuery('#allList').addClass('-show');
    
    if (jQuery('#allList').hasClass('slick-slider')){
        $('#allList').slick("unslick");
    };

    if (jQuery('#pastriesList').hasClass('slick-slider')){
        $('#pastriesList').slick("unslick");
    };

    if (jQuery('#cakesList').hasClass('slick-slider')){
        $('#cakesList').slick("unslick");
    };

    if (jQuery('#bakeAtHomeList').hasClass('slick-slider')){
        $('#bakeAtHomeList').slick("unslick");
    };

    if (jQuery('#mixList').hasClass('slick-slider')){
        $('#mixList').slick("unslick");
    };

    $('#allList').slick({
        dots: false,
        infinite: true,
        margin: 20,
        slidesToShow: 4,
        arrows: true,
        nextArrow: '<button aria-label="Next" class="m-slick-arrow -next slick-next"><i class="fas fa-chevron-right m-slick-arrow-icon"></i></button>',
        prevArrow: '<button aria-label="Previous" class="m-slick-arrow -prev slick-prev me-4"><i class="fas fa-chevron-left m-slick-arrow-icon"></i></button>',
        draggable: true,
        appendArrows: jQuery(this).parent(),
        customPaging: function(slider, i) {
            return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
        },
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    arrows: true,

                }
            },
            {
                breakpoint: 767,

                settings: {
                    slidesToShow: 1,
                    arrows: true,
                }
            }
        ]
        });


    jQuery('#pastriesList').removeClass('-show');
    jQuery('#cakesList').removeClass('-show');
    jQuery('#bakeAtHomeList').removeClass('-show');
    jQuery('#mixList').removeClass('-show');

});

jQuery('#pastriesButton').on('click', function() {
    jQuery('#pastriesList').toggleClass('-show');

    if (jQuery('#allList').hasClass('slick-slider')){
        $('#allList').slick("unslick");
    };

    if (jQuery('#pastriesList').hasClass('slick-slider')){
        $('#pastriesList').slick("unslick");
    };

    if (jQuery('#cakesList').hasClass('slick-slider')){
        $('#cakesList').slick("unslick");
    };

    if (jQuery('#bakeAtHomeList').hasClass('slick-slider')){
        $('#bakeAtHomeList').slick("unslick");
    };

    if (jQuery('#mixList').hasClass('slick-slider')){
        $('#mixList').slick("unslick");
    };

    $('#pastriesList').slick({
        dots: false,
        infinite: true,
        margin: 20,
        slidesToShow: 4,
        arrows: true,
        nextArrow: '<button aria-label="Next" class="m-slick-arrow -next slick-next"><i class="fas fa-chevron-right m-slick-arrow-icon"></i></button>',
        prevArrow: '<button aria-label="Previous" class="m-slick-arrow -prev slick-prev me-4"><i class="fas fa-chevron-left m-slick-arrow-icon"></i></button>',
        draggable: true,
        appendArrows: jQuery(this).parent(),
        customPaging: function(slider, i) {
            return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
        },
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    arrows: true,

                }
            },
            {
                breakpoint: 767,

                settings: {
                    slidesToShow: 1,
                    arrows: true,
                }
            }
        ]
        });
    
    jQuery('#allList').removeClass('-show');
    jQuery('#cakesList').removeClass('-show');
    jQuery('#bakeAtHomeList').removeClass('-show');
    jQuery('#mixList').removeClass('-show');
});

jQuery('#cakesButton').on('click', function() {
    jQuery('#cakesList').addClass('-show');

    if (jQuery('#allList').hasClass('slick-slider')){
        $('#allList').slick("unslick");
    };

    if (jQuery('#pastriesList').hasClass('slick-slider')){
        $('#pastriesList').slick("unslick");
    };

    if (jQuery('#cakesList').hasClass('slick-slider')){
        $('#cakesList').slick("unslick");
    };

    if (jQuery('#bakeAtHomeList').hasClass('slick-slider')){
        $('#bakeAtHomeList').slick("unslick");
    };

    if (jQuery('#mixList').hasClass('slick-slider')){
        $('#mixList').slick("unslick");
    };

    $('#cakesList').slick({
        dots: false,
        infinite: true,
        margin: 20,
        slidesToShow: 4,
        arrows: true,
        nextArrow: '<button aria-label="Next" class="m-slick-arrow -next slick-next"><i class="fas fa-chevron-right m-slick-arrow-icon"></i></button>',
        prevArrow: '<button aria-label="Previous" class="m-slick-arrow -prev slick-prev me-4"><i class="fas fa-chevron-left m-slick-arrow-icon"></i></button>',
        draggable: true,
        appendArrows: jQuery(this).parent(),
        customPaging: function(slider, i) {
            return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
        },
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    arrows: true,

                }
            },
            {
                breakpoint: 767,

                settings: {
                    slidesToShow: 1,
                    arrows: true,
                }
            }
        ]
        });
    
    
    jQuery('#allList').removeClass('-show');
    jQuery('#pastriesList').removeClass('-show');
    jQuery('#bakeAtHomeList').removeClass('-show');
    jQuery('#mixList').removeClass('-show');
});

jQuery('#bakeAtHomeButton').on('click', function() {
    jQuery('#bakeAtHomeList').addClass('-show');

    if (jQuery('#allList').hasClass('slick-slider')){
        $('#allList').slick("unslick");
    };

    if (jQuery('#pastriesList').hasClass('slick-slider')){
        $('#pastriesList').slick("unslick");
    };

    if (jQuery('#cakesList').hasClass('slick-slider')){
        $('#cakesList').slick("unslick");
    };

    if (jQuery('#bakeAtHomeList').hasClass('slick-slider')){
        $('#bakeAtHomeList').slick("unslick");
    };

    if (jQuery('#mixList').hasClass('slick-slider')){
        $('#mixList').slick("unslick");
    };

    $('#bakeAtHomeList').slick({
        dots: false,
        infinite: true,
        margin: 20,
        slidesToShow: 4,
        arrows: true,
        nextArrow: '<button aria-label="Next" class="m-slick-arrow -next slick-next"><i class="fas fa-chevron-right m-slick-arrow-icon"></i></button>',
        prevArrow: '<button aria-label="Previous" lass="m-slick-arrow -prev slick-prev me-4"><i class="fas fa-chevron-left m-slick-arrow-icon"></i></button>',
        draggable: true,
        appendArrows: jQuery(this).parent(),
        customPaging: function(slider, i) {
            return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
        },
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    arrows: true,

                }
            },
            {
                breakpoint: 767,

                settings: {
                    slidesToShow: 1,
                    arrows: true,
                }
            }
        ]
        });
    
    
    jQuery('#allList').removeClass('-show');
    jQuery('#pastriesList').removeClass('-show');
    jQuery('#cakesList').removeClass('-show');
    jQuery('#mixList').removeClass('-show');
});

jQuery('#mixButton').on('click', function() {
    jQuery('#mixList').addClass('-show');

    if (jQuery('#allList').hasClass('slick-slider')){
        $('#allList').slick("unslick");
    };

    if (jQuery('#pastriesList').hasClass('slick-slider')){
        $('#pastriesList').slick("unslick");
    };

    if (jQuery('#cakesList').hasClass('slick-slider')){
        $('#cakesList').slick("unslick");
    };

    if (jQuery('#bakeAtHomeList').hasClass('slick-slider')){
        $('#bakeAtHomeList').slick("unslick");
    };

    if (jQuery('#mixList').hasClass('slick-slider')){
        $('#mixList').slick("unslick");
    };

    $('#mixList').slick({
        dots: false,
        infinite: true,
        margin: 20,
        slidesToShow: 4,
        arrows: true,
        nextArrow: '<button aria-label="Next" class="m-slick-arrow -next slick-next"><i class="fas fa-chevron-right m-slick-arrow-icon"></i></button>',
        prevArrow: '<button aria-label="Previous" class="m-slick-arrow -prev slick-prev me-4"><i class="fas fa-chevron-left m-slick-arrow-icon"></i></button>',
        draggable: true,
        appendArrows: jQuery(this).parent(),
        customPaging: function(slider, i) {
            return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
        },
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    arrows: true

                }
            },
            {
                breakpoint: 767,

                settings: {
                    slidesToShow: 1,
                    arrows: true,
                }
            }
        ]
        });
    
    
    jQuery('#allList').removeClass('-show');
    jQuery('#pastriesList').removeClass('-show');
    jQuery('#cakesList').removeClass('-show');
    jQuery('#bakeAtHomeList').removeClass('-show');
});