import 'jquery';

jQuery(document).ready(function () {
    jQuery(document).on('click', ".item.search-able", function() {
        jQuery(".item.search form").submit();
    });

    jQuery(".item.search").click(function(e){
        jQuery(this).addClass("search-able");
        jQuery(".item.search input").focus();
        e.stopPropagation();
    });

    jQuery("#howCanWeHelpYou").change(function(e){
        window.location.href = jQuery(this).val();
    });
    
    jQuery(".m-content-filter .button.-viewMore").click(function(e) {
        e.preventDefault();
        jQuery(this).closest('form').find('.fields.-additional').toggleClass('-active'); 
    });
});