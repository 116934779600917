import { default as $ } from 'jquery';

$(document).ready(function() {

    
    $('.m-post-related-carousel.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" type="button" class="slick-prev me-5"><i class="fa-solid fa-chevron-left" aria-hidden="true"></i></button>',
        nextArrow: '<button aria-label="Next" aria-label="Next" type="button" class="slick-next"><i class="fa-solid fa-chevron-right" aria-hidden="true"></i></button>',
        appendArrows: $('.m-post-related-arrows'),
        draggable: true,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 930,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});